import { UserManager } from "oidc-client";
import { logInfo, logWarn } from "./utils/logger";
import { AuthConfig, AuthResponseType, AuthSession, AuthUser } from "./type";


import OIDC from "./OIDC";
import SessionServiceClient from "./sessionsvc/SessionServiceClient";
import OidcUserManagerFactory from "./oidc/OidcUserManagerFactory";
import UserTokenManager from "./utils/UserTokenManager";
import CognitoAuthHandler from "./authhandler/CognitoAuthHandler";
import MidwayAuthHandler from "./authhandler/MidwayAuthHandler";
import AmplifyConfiguration from "./amplify/AmplifyConfiguration";
import { CognitoUser } from "amazon-cognito-identity-js";
import AmplifyAuth from "@aws-amplify/auth";

export default class AuthManager {

    private userManager : UserManager | undefined;
    private userKey : string | undefined;
    private authConfig : AuthConfig | undefined;
    private authFlowType : string | undefined;
    private useSelfHostedLoginUI : boolean | undefined;

    private sessionServiceClient : SessionServiceClient | undefined;
    private authHandler : CognitoAuthHandler | MidwayAuthHandler | undefined;
    private userTokenManager : UserTokenManager | undefined;

    public getUserManager() : UserManager | undefined {
        return this.userManager;
    }

    public setUserManager(userManager : UserManager | undefined) : void {
        this.userManager = userManager;
    }

    public setSessionServiceClient(sessionSvcClient : SessionServiceClient | undefined) : void {
        this.sessionServiceClient = sessionSvcClient;
    }

    public setAuthHandler(authHandler : CognitoAuthHandler | MidwayAuthHandler | undefined) : void {
        this.authHandler = authHandler;
    }

    /**
     * Configure AuthManager instance by taking portal config {@link AuthConfig} object as input.
     * This method needs to be invoked before AuthManaged can actually be used
     * @param config
     */
    public configure (config : AuthConfig) : void {
        this.useSelfHostedLoginUI = (config.useSelfHostedLoginUI === true) && !this.isIntegrationTestUser();
        this.authConfig = config;
        this.authFlowType = config.responseType;

        // userKey used to save token inside local storage.
        this.userKey = 'oidc.user:' + config.identityProviderUrl + ':' + config.clientId;

        this.userManager = OidcUserManagerFactory.getUserManager(config);
        this.sessionServiceClient = new SessionServiceClient(config.sessionServiceEndpoint);
        this.userTokenManager = new UserTokenManager(this.userKey, this.authConfig);

        // initialize amplify configuration only if self-hosted UI is enabled
        if (this.useSelfHostedLoginUI === true) {
            AmplifyConfiguration.initialize(config);
        }

        // instantiate different auth handler based on the auth flow type.
        // for portal instance where cognito is used (PDX and potentially the upcoming PDT region), CognitoAuthHandler will be applied.
        // otherwise, MidwayAuthHandler will be applied (ZHY, MVP).
        this.authHandler = this.authFlowType == AuthResponseType.Code ?
          new CognitoAuthHandler(this.userManager, this.sessionServiceClient, this.authConfig) :
          new MidwayAuthHandler(this.userManager, this.sessionServiceClient, this.authConfig);
    }

    /**
     * Temporary helper function to operate the user-pool-migration. Once the users are all migrated
     * this function will be removed.
     *
     * @param amplifyAuthenticationFlowType
     */
    public changeAmplifyAuthFlowType (amplifyAuthenticationFlowType: string) : void {
        if( this.authConfig ) {
            AmplifyConfiguration.updateAmplifyAuthenticationFlowType(this.authConfig, amplifyAuthenticationFlowType);
        }
    }

    /**
     * Validates the current login status and kicks off the signIn flow if user is found to be unauthenticated.
     *
     * @return {AuthSession} return auth session object if signIn is successful.
     */
    public async signinSession() : Promise<AuthSession | null> {
        // check if already signed in
        let session;
        if (await this.isCurrentUserAuthenticated() && (session = await this.getSession())) {
            return session;
        }

        try {
            if (this.authHandler) {
                await this.authHandler.signIn();
            }
            return await this.getSession();
        } catch (e) {
            logWarn('AuthManager.signinSessionError', e);
            throw(e);
        }
    }

    /**
     * Refreshes token and session credentials by triggering the silentSignIn flow.
     *
     * @return {AuthSession} session containing the id_token and session credentials.
     */
    public async refreshSession() : Promise<AuthSession | null> {
        if (!this.authHandler || !this.sessionServiceClient) {
            return null;
        }
        const currentUser = await this.authHandler
             .silentSignIn()
             .then(() => this.getUser())
             .catch((error) => {
                 // It will enter here, if the silentSignIn flow fails.
                 logWarn("AuthManager",`silentSignIn() failed with error: ${error}`);
                 // Force sign out the user if their refresh token was expired, 
                 // or compromised, as there is no value continuing session
                 if(error.code === 'NotAuthorizedException' || error.code === 'ExpiredCodeException') {
                     this.signOut();
                 }
                 // for all the other exceptions handle it in the ArgoCore.
                 throw (error);
             });
	
        // clean the stale state for current user proactively when refresh session
        if(this.userManager) {
            this.userManager.clearStaleState();
        }

        // It is expected to enter here, only when the getUser() call fails and return with null
        if (!currentUser) {
            logWarn("AuthManager", "No valid current login user found, session refresh flow failed.")
            return null;
        }

        try {
            return await this.sessionServiceClient.getCredentialsFromToken(currentUser.id_token)
              .then(() => this.getSession());
        } catch (e) {
            logWarn('AuthManager.refresh call session service fail', e);
            throw (e);
        }
    }

    /**
     * Gets current sign in user from browser local storage.
     * @return {AuthUser}
     */
    public async getUser() : Promise<AuthUser | null> {
        if (!this.authConfig || !this.userTokenManager) {
            return null;
        }

        return await this.userTokenManager.getUserFromStorage();
    }

    public async isCurrentUserAuthenticated() : Promise<boolean> {
        try {
            return await this.getUser() != null;
        } catch (e) {
            logInfo('AuthManager', 'Check init user fail, user is not logged in yet');
            return false;
        }
    }

    /**
     * Gets current sign in user's session from browser local storage.
     * @return {AuthSession}
     */
    public async getSession() : Promise<AuthSession | null> {
        const user = await this.getUser();
        if (user && this.sessionServiceClient) {
            return await this.sessionServiceClient.getSessionFromLocalStorage(user);
        } else {
            return null;
        }
    }

    /**
     * Federated SignIn method used for single sign on (SSO) users.
     * Note: This is method is only used in new self-hosted login UI.
     *
     * @param providerName SSO provider name. i.e. Amazon (the only use case we have now but can be expanded in future).
     * @param customState custom state to preserve (usually the original poth name like '/townsend').
     */
    public async federatedSignIn(providerName: string, customState: string): Promise<void> {
        // clean the stale state before user login
        if(this.userManager) {
            await this.userManager.clearStaleState();
        }

        if (this.authHandler && this.authHandler instanceof CognitoAuthHandler) {
            await this.authHandler.federatedSignIn(providerName, customState);
        } else {
            throw new Error("Federated sign in is not supported");
        }
    }

    /**
     * Sign in method used for new self-hosted login UI. Successful sign in call may not return authentication token immediately,
     * additional Auth challenge (i.e. MFA) might be required, and it should be answered by {@link landingPageSignIn}.
     *
     * @param username username as string
     * @param pwd password as string
     */
    public async landingPageSignIn(username: string, pwd: string): Promise<CognitoUser> {
        // clean the stale state before user login
        if(this.userManager) {
            await this.userManager.clearStaleState();
        }
        if (this.authHandler && this.authHandler instanceof CognitoAuthHandler) {
            return await this.authHandler.landingPageSignIn(username, pwd);
        } else {
            throw new Error("Landing page sign in is not supported");
        }
    }

    /**
     * Responds auth challenge required for MFA. If succeeds, redirect to landing page or the path that is preserved before.
     * Note: This is method is only used in new self-hosted login UI.
     *
     * @param challengeResponse custom auth challenge response required for MFA (confirmation code).
     * @param user cognito user to respond Auth challenge for.
     */
    public async respondAuthChallenge(challengeResponse: string, user: CognitoUser): Promise<void> {
        if (this.authHandler && this.authHandler instanceof CognitoAuthHandler) {
            await this.authHandler.respondAuthChallenge(challengeResponse, user);
        } else {
            throw new Error("Respond to auth challenge operation is not supported");
        }
    }

    /**
     * Initiate password reset flow.
     * @param username username to reset password.
     */
    public async initiatePasswordReset(username : string) : Promise<void> {
        if (this.useSelfHostedLoginUI === true) {
            await AmplifyAuth.forgotPassword(username);
        } else {
            throw new Error("Initiate password reset operation is not supported");
        }
    }

    /**
     * Submit new password during the reset password flow.
     *
     * @param username username to reset password.
     * @param authCode auth code received in user email.
     * @param newPassword new password to set.
     */
    public async submitPasswordReset(username : string, authCode: string, newPassword : string) : Promise<void> {
        if (this.useSelfHostedLoginUI === true) {
            await AmplifyAuth.forgotPasswordSubmit(username, authCode, newPassword);
        } else {
            throw new Error("Password reset operation is not supported");
        }
    }

    /**
     * Logs out current login user from portal and clean up local storage.
     */
    public async signOut() : Promise<void> {
        if (!this.userManager || !this.authConfig) {
            return;
        }
        if (this.userKey) {
            window.localStorage.removeItem(this.userKey);
        }
        window.localStorage.removeItem(SessionServiceClient.sessSvcKey);

        for (const key in localStorage) {
            if (key.startsWith('oidc')) {
                localStorage.removeItem(key);
            }
        }

        if (this.useSelfHostedLoginUI == true) {
            await AmplifyAuth.signOut();
            window.location.replace(this.authConfig.redirectSignOut);
        } else {
            try {
                await this.userManager.signoutRedirect({
                    id_token_hint: localStorage.getItem("id_token"),
                    extraQueryParams: {
                        'client_id': this.authConfig.clientId,
                        'redirect_uri': this.authConfig.redirectSignOut,
                        'response_type': this.authFlowType,
                    },
                });
            } catch (e) {
                logWarn('AuthManager.signOut failure', e);
                throw (e);
            }
        }
        this.userManager.clearStaleState();
    }

    public isIntegrationTestUser() : boolean {
        return window.localStorage.getItem('oidc.mockstate') !== null
          && window.localStorage.getItem('oidc.mockstate') !== undefined;
    }
}

export const Auth = new AuthManager();
OIDC.register(Auth);
